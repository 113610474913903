import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  TextField,
  InputAdornment,
  Typography,
  TablePagination,
  Chip
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const LicenseTable = () => {
  const [licenses, setLicenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        const response = await axios.get(`${apiUrl}/licenses`);
        const sortedLicenses = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setLicenses(sortedLicenses);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching licenses:", error);
        setLoading(false);
      }
    };
    fetchLicenses();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter licenses based on search term and paginate
  const filteredLicenses = licenses
    .filter((license) =>
      license.tg_username.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Container sx={{ marginTop: 1, maxWidth: "1800px !important" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 24,
        }}
      >
        <Typography variant="h4">Licenses</Typography>
      </div>
      <TextField
        placeholder="Search by telegram name"
        variant="outlined"
        size="small"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: "30%", marginBottom: 2 }}
      />
      <TableContainer component={Card} sx={{ borderRadius: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" padding="12px">
                No#
              </TableCell>
              <TableCell align="left" padding="12px">
                Created At
              </TableCell>
              <TableCell align="left" padding="12px">
                Telegram username
              </TableCell>
              <TableCell align="left" padding="12px">
                License Key
              </TableCell>
              <TableCell align="left" padding="12px">
                Cost
              </TableCell>
              <TableCell align="left" padding="12px">
                Expiration Date
              </TableCell>
              <TableCell align="left" padding="12px">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLicenses.map((license, index) => (
              <TableRow key={index}>
                <TableCell sx={{ padding: "16px" }}>
                  {index + 1 + page * rowsPerPage}
                </TableCell>
                <TableCell sx={{ padding: "16px" }}>
                  {license.created_at}
                </TableCell>
                <TableCell sx={{ padding: "16px" }}>
                  {license.tg_username}
                </TableCell>
                <TableCell sx={{ padding: "16px" }}>
                  {license.license_key}
                </TableCell>
                <TableCell sx={{ padding: "16px" }}>{license.price}</TableCell>
                <TableCell sx={{ padding: "16px" }}>
                  {license.expired_at || 0}
                </TableCell>
                <TableCell sx={{ padding: "16px" }}>
                  <Chip
                    label={
                      new Date(license.expired_at) < new Date()
                        ? "Expired"
                        : "Active"
                    }
                    color={
                      new Date(license.expired_at) < new Date()
                        ? "error"
                        : "success"
                    }
                    size="small"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={licenses.length}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[10, 25, 50, 100]}
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
      />
    </Container>
  );
};

export default LicenseTable;
