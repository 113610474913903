import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Avatar,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

const SignIn = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSignIn = async () => {
    if (!email || !password) {
      alert("Please enter valid credentials!");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/signin`, {
        email,
        password,
      });

      if (response.status === 201) {
        setIsAuthenticated(true);

        // Store authentication status in local storage
        localStorage.setItem("isAuthenticated", "true");

        // Show success notification
        toast.success("Welcome back!", {
          position: "top-right",
        });

        setTimeout(() => {
          navigate("/"); // Redirect to the main page
        }, 2000); // Wait for 2 seconds before redirecting
      } else {
        alert("Authentication failed. Please try again.");
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
      toast.error(
        error.response?.data?.message ||
          "Incorrect email or password. Try again.",
        {
          position: "top-right",
        }
      );
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSignIn();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: "linear-gradient(135deg, #6a11cb, #2575fc)",
        padding: 2,
      }}
      onKeyDown={handleKeyDown}
    >
      <ToastContainer />
      <Paper
        elevation={10}
        sx={{
          padding: 4,
          borderRadius: 3,
          maxWidth: 400,
          width: "100%",
          textAlign: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Avatar
          sx={{
            backgroundColor: "#6a11cb",
            margin: "0 auto",
            marginBottom: 2,
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5" sx={{ marginBottom: 2, color: "#333" }}>
          Welcome Back!
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#6a11cb",
            color: "#fff",
            padding: "10px 0",
            "&:hover": { backgroundColor: "#2575fc" },
          }}
          onClick={handleSignIn}
        >
          Sign In
        </Button>
        <Button
          fullWidth
          sx={{ marginTop: 2, color: "#6a11cb" }}
          onClick={() => navigate("/signup")}
        >
          Not have an account yet? Sign Up
        </Button>
      </Paper>
    </Box>
  );
};

export default SignIn;
